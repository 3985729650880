import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Introduction = ({handleFormChange, selectedState,setSelectedState, value, setAuthenticated, setHasError}) => {
  const [showError, setShowError ] = useState(false);
  useEffect(()=> {
    setHasError(false)
  },[setHasError])
  const handleSubmit = async() => {
    if(value === process.env.REACT_APP_PASSWORD) {
      setAuthenticated(true);
      setSelectedState(selectedState+1);
    }else {
      setShowError(true);
      setHasError(true);
    }
  }
  return (
    <>
    <div className="content">
      <p>
        Want to show your appreciation for a nurse who rocks?
        <br />
        Let them know by sending a Nurses Rock! customized eCard!
      </p>
      <div className="input">
        <label id="password-label" for="password">
          <input
            id="password"
            type="password"
            onChange={handleFormChange}
            name="password"
            placeholder="Enter Password"
            value={value}
          />
        </label>
        <div className="btn" onClick={handleSubmit}/>
      </div>
      {showError && <div class="error">Incorrect Password!</div>}
      <div className="note">Intended for use on laptop only</div>
    </div>
    </>
  );
};

Introduction.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  selectedState: PropTypes.number.isRequired,
  setSelectedState: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  setAuthenticated: PropTypes.func.isRequired,
  setHasError: PropTypes.func.isRequired,
}
export default Introduction;
