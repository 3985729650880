import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Name = ({handleFormChange, selectedState,setSelectedState, value}) => {
  const [showError, setShowError ] = useState(false);
  const handleStateChange = () => {
    if(value){
      setSelectedState(selectedState+1);
    }else {
      setShowError(true)
    }
  }
  return (
    <div class="content">
      <div class="input">
        <label for="yourName">
          <input
            id="yourName"
            type="text"
            name="yourName"
            placeholder="Enter Your Name"
            value={value}
            onChange={handleFormChange}
          />
        </label>
        <div className="btn" onClick={handleStateChange}></div>
      </div>
      {showError && <div class="error">REQUIRED</div>}
    </div>
  );
};

Name.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  selectedState: PropTypes.number.isRequired,
  setSelectedState: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  setAuthenticated: PropTypes.func.isRequired,
}

export default Name;
