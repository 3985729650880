import React, { useEffect, useRef, useState } from 'react';
import { db } from '../../firebase';
import { CSVLink } from "react-csv";

const ReportPage = () => {
  const csvRef = useRef(null);
  const [showError, setShowError] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [password, setPassword] = useState();
  const [csvData, setCsvData] = useState(
    [
      ["Sender", "Recievers", "date"],
    ]
  );
  const handleSubmit = async () => {
    if (password === process.env.REACT_APP_PASSWORD) {
      setAuthenticated(true);
    } else {
      setShowError(true);
    }
  };
  const downloadReport = async () => {
    const dbRef = await db.ref(`/${process.env.REACT_APP_FIREBASE_NODE}`).once('value');
    const data = Object.values(dbRef.val());
    const rows = data.map(row => [`${row.senderName} (${row.yourEmail})`, `${row.recipientName} (${row.recipientEmail})`, row.createdAt ?? '-'])
    setCsvData([...csvData, ...rows ])
    setTimeout(()=> {
      csvRef.current.link.click()
    }, 500)
  }

  return (
    <body className={`state-1`}>
      <div className="bar top" />
      <div className="guitar" />
      <div className="header">
        <a className="logo" />
      </div>
      <div className="body">
        <div className={`panel panel-1`}>
          <div className="content">
            <br />
            {authenticated ? (
              <div>
                <br/>
                <CSVLink
                  data={csvData}
                  filename={'nurses-rock.csv'}
                  style={{display: 'none'}}
                  ref={csvRef}
                  target="_blank" 
              />
                <p>Click below to download the report</p>
                <button className="btn btn-lg btn-block btn-red" onClick={downloadReport}>Download report</button>
              </div>
            ) : (
              <>
                <p className="mt-3">Please login to download the report</p>
                <div className="input">
                  <label id="password-label" for="password">
                    <input
                      id="password"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      name="password"
                      placeholder="Enter Password"
                      value={password}
                    />
                  </label>
                  <div className="btn" onClick={handleSubmit} />
                </div>
                <div className="note">Intended for use on laptop only</div>
                {showError && <div class="error">Incorrect Password!</div>}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="glogo" />
      <div className="bar bottom" />
    </body>
  );
};

export default ReportPage;
