import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import defaultImage from '../../assets/images/card-image-default.png';
import { db } from '../../firebase';

const View = () => {
  const id = useParams()?.id;
  const navigate = useNavigate();
  const [value, setValue] = useState();
  if (!id) {
    navigate('/');
  }
  const getData = async (cardId) => {
    const ref = await db.ref(`/${process.env.REACT_APP_FIREBASE_NODE}/${cardId}`);
    ref.on('value', (snap) => {
      const value = snap.val();
      setValue(value);
    });
  };

  useEffect(()=> {
    getData(id);
  },[id])


  return (
    <body className="view">
      <div className="bar top"></div>
      <div className="body">
        <div className="card-left"></div>
        <div className="preview">
          <div className="to">
            To:<span>{value?.recipientName}</span>
          </div>
          <div className="middle">
            <div className="copy"></div>
            <div className="image">
              <img src={value?.photoUrl ? value.photoUrl: defaultImage } />
            </div>
          </div>
          <div className="from">
            From:<span>{value?.senderName}</span>
            <span>{value?.yourEmail}</span>
          </div>
        </div>
      </div>
      <div className="bar bottom"></div>
    </body>
  );
};
export default View;
