import axios from 'axios'

const API_URL = 'https://mail.boco-delphire.com/api/email/send/';

const EmailService = {
  send: (data) => {
    return axios.post(API_URL, data);
  },
};

export default EmailService;