import React from "react";
import './assets/scss/main.scss'
import { Routes, Route } from "react-router-dom";
import InformationPage from "./pages/Information/Info";
import ViewPage from "./pages/View/View";
import ReportPage from "./pages/Report/Report";

const Router = () => {
  return (
    <Routes>
      <Route path="/view/:id" element={<ViewPage />} />
      <Route exact path="/" element={<InformationPage />} />
      <Route exact path="/report" element={<ReportPage />} />
    </Routes>
  );
};

export default Router;
